export { default as adjectives } from "./adjectives.svg"
export { default as animals } from "./animals.svg"
export { default as careers } from "./careers.svg"
export { default as clothing } from "./clothing.svg"
export { default as colors } from "./colors.svg"
export { default as countries } from "./countries.svg"
export { default as date } from "./date.svg"
export { default as drinks } from "./drinks.svg"
export { default as food } from "./food.svg"
export { default as health } from "./health.svg"
export { default as household } from "./household.svg"
export { default as locations } from "./locations.svg"
export { default as money } from "./money.svg"
export { default as numbers } from "./numbers.svg"
export { default as people } from "./people.svg"
export { default as prepositions } from "./prepositions.svg"
export { default as travel } from "./travel.svg"
